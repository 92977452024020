import { isPlatformBrowser } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { slideOutHorizontalAnimation } from '@freelancer/animations';
import { Auth } from '@freelancer/auth';
import type { DatastoreCollection } from '@freelancer/datastore';
import { Datastore } from '@freelancer/datastore';
import type {
  ReferralInvitationsCollection,
  ReferralTokenCollection,
  User,
  UsersCollection,
} from '@freelancer/datastore/collections';
import { GiveGetService } from '@freelancer/give-get';
import { LocalStorage } from '@freelancer/local-storage';
import { FreelancerCurrencyPipe } from '@freelancer/pipes';
import { REFERRER_QUERY_PARAM } from '@freelancer/share';
import { ButtonColor, ButtonSize } from '@freelancer/ui/button';
import { CardSize } from '@freelancer/ui/card';
import { IconColor, IconSize } from '@freelancer/ui/icon';
import { LinkColor, LinkUnderline } from '@freelancer/ui/link';
import { ModalService, ModalSize } from '@freelancer/ui/modal';
import { SpinnerSize } from '@freelancer/ui/spinner';
import { FontColor, FontWeight, TextSize } from '@freelancer/ui/text';
import { ToastAlertService, ToastAlertType } from '@freelancer/ui/toast-alert';
import { AvatarSize } from '@freelancer/ui/user-avatar';
import { isDefined } from '@freelancer/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReferralInvitationStatusApi } from 'api-typings/common/common';
import { ShellConfig } from 'app/app-shell';
import { LoginOrSignup } from 'app/login-signup/login-signup-modal';
import {
  Subscription,
  combineLatest,
  firstValueFrom,
  map,
  of,
  type Observable,
} from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@UntilDestroy({ className: 'GiveGetFooterComponent' })
@Component({
  selector: 'app-give-get-footer',
  template: `
    <div
      *ngIf="showGiveGetFooter$ | flAsync"
      class="GiveGetFooter-container"
      [class.GiveGetFooter-slideIn]="isBrowser"
      flTrackingSection="GiveGetFooter"
      [@slideOutHorizontalAnimation]="{
        value: undefined,
        params: {
          duration: 200,
        },
      }"
    >
      <fl-card
        class="GiveGetFooter"
        [edgeToEdge]="true"
        [size]="CardSize.SMALL"
      >
        <div
          class="GiveGetFooter-body"
          [class.GiveGetFooter-darkBackground]="isFooterDarkMode$ | flAsync"
        >
          <ng-container *ngIf="isReady$ | flAsync; else loadingState">
            <ng-container *ngIf="user$ | flAsync as user">
              <ng-container *ngIf="bonusAmount$ | flAsync as bonusAmount">
                <div
                  class="GiveGetFooter-titleContainer"
                  [flMarginBottom]="'xsmall'"
                >
                  <fl-user-avatar
                    [flMarginRight]="'small'"
                    [users]="[user]"
                    [size]="AvatarSize.MID"
                  ></fl-user-avatar>
                  <fl-text
                    i18n="Give get footer header"
                    [color]="
                      (isFooterDarkMode$ | flAsync)
                        ? FontColor.LIGHT
                        : FontColor.INHERIT
                    "
                    [weight]="FontWeight.BOLD"
                    [size]="TextSize.SMALL"
                    [sizeTablet]="TextSize.MARKETING_SMALL"
                    [flMarginRight]="'xsmall'"
                  >
                    {{ user.displayName }} has given you
                    {{ bonusAmount }}
                    to try Freelancer
                  </fl-text>
                </div>
                <fl-text
                  i18n="Give get footer text"
                  [displayLineBreaks]="false"
                  [size]="TextSize.XSMALL"
                  [sizeTablet]="TextSize.SMALL"
                  [color]="
                    (isFooterDarkMode$ | flAsync)
                      ? FontColor.LIGHT
                      : FontColor.INHERIT
                  "
                  [flMarginBottom]="'small'"
                >
                  Get started today and use your
                  {{ bonusAmount.split(' ')[0] }} to get anything done! <br />
                  Valid for new users only,
                  <fl-link
                    flTrackingLabel="GiveGetFooter-T&C"
                    [size]="TextSize.XSMALL"
                    [sizeTablet]="TextSize.SMALL"
                    [color]="
                      (isFooterDarkMode$ | flAsync)
                        ? LinkColor.LIGHT
                        : LinkColor.INHERIT
                    "
                    [link]="'/get/' + user.username"
                    [underline]="LinkUnderline.ALWAYS"
                    [queryParams]="{
                      ref: 'social-referral-terms',
                    }"
                  >
                    T&Cs apply.
                  </fl-link>
                </fl-text>

                <div class="GiveGetFooter-cta-container">
                  <div
                    class="GiveGetFooter-cta-fullWidth"
                    [flMarginBottom]="'xsmall'"
                    [flMarginBottomTablet]="'none'"
                    [flMarginRight]="'none'"
                    [flMarginRightTablet]="'large'"
                  >
                    <fl-button
                      i18n="Claim bonus amount"
                      flTrackingLabel="GiveGetFooter-ClaimBonus"
                      [color]="ButtonColor.PRIMARY"
                      [display]="'block'"
                      [size]="ButtonSize.SMALL"
                      [sizeTablet]="ButtonSize.MID"
                      (click)="openLoginSignupModal()"
                    >
                      Claim my
                      {{ bonusAmount.split(' ')[0] }}
                    </fl-button>
                  </div>
                  <fl-link
                    i18n="What can user do with bonus amount"
                    flTrackingLabel="GiveGetFooter-WhatCanIDoWithIt"
                    [color]="LinkColor.PRIMARY"
                    [link]="'/get/' + user.username"
                    [queryParams]="{
                      ref: 'social-referral-terms',
                    }"
                    [size]="TextSize.XXSMALL"
                    [sizeTablet]="TextSize.XSMALL"
                    [flMarginBottom]="'xxsmall'"
                    [flMarginBottomTablet]="'none'"
                    >What can I do with it?
                  </fl-link>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>

        <fl-icon
          class="GiveGetFooter-closeIcon"
          flTrackingLabel="GiveGetter-CloseIcon"
          label="Close icon"
          i18n-label="Close icon"
          [color]="
            (isFooterDarkMode$ | flAsync)
              ? IconColor.LIGHT
              : IconColor.FOREGROUND
          "
          [name]="'ui-close'"
          [size]="IconSize.SMALL"
          [hoverAnimation]="'spin-and-highlight'"
          (click)="closeFooter()"
        ></fl-icon>
      </fl-card>
    </div>

    <ng-template #loadingState>
      <fl-spinner
        class="GiveGetFooter-loadingState"
        flTrackingLabel="GiveGetFooterLoading"
        [size]="SpinnerSize.LARGE"
      ></fl-spinner>
    </ng-template>

    <fl-toast-alert
      i18n="Give Get Email Send Success Message"
      [id]="'give-get-email-send-success'"
    >
      Thanks! We’ve emailed you a link to claim your free credit.
    </fl-toast-alert>
    <fl-toast-alert
      i18n="Give Get Email Send Error Message"
      [id]="'give-get-email-send-error'"
      [type]="ToastAlertType.ERROR"
    >
      Something went wrong while sending your email. Please try again.
    </fl-toast-alert>
  `,
  styleUrls: ['./give-get-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideOutHorizontalAnimation],
})
export class GiveGetFooterComponent implements OnInit, OnDestroy {
  AvatarSize = AvatarSize;
  ButtonColor = ButtonColor;
  ButtonSize = ButtonSize;
  CardSize = CardSize;
  FontColor = FontColor;
  FontWeight = FontWeight;
  IconColor = IconColor;
  IconSize = IconSize;
  LinkColor = LinkColor;
  LinkUnderline = LinkUnderline;
  LoginOrSignup = LoginOrSignup;
  ModalSize = ModalSize;
  SpinnerSize = SpinnerSize;
  TextSize = TextSize;
  ToastAlertType = ToastAlertType;

  isBrowser = false;
  user$: Observable<User>;
  bonusAmount$: Observable<string | null>;

  isFooterDarkMode$: Observable<boolean>;
  isReady$: Observable<boolean>;
  showGiveGetFooter$: Observable<boolean>;
  userCollection: DatastoreCollection<UsersCollection>;

  private subscriptions = new Subscription();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private activatedRoute: ActivatedRoute,
    private auth: Auth,
    private currencyPipe: FreelancerCurrencyPipe,
    private datastore: Datastore,
    private giveGetService: GiveGetService,
    private localStorage: LocalStorage,
    private modalService: ModalService,
    private shellConfig: ShellConfig,
    private toastAlertService: ToastAlertService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    // changes card theme opposite to the page theme to emphasize the card
    this.isFooterDarkMode$ = this.shellConfig
      .getConfig(this.activatedRoute)
      .pipe(map(config => !config.isDarkMode));

    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;

      const localUsername$ = this.localStorage.get('giveGetReferrerUsername');

      const latestQueryParamLocaStorage$ = combineLatest([
        this.activatedRoute.queryParams.pipe(
          map(queryParamMap => queryParamMap[REFERRER_QUERY_PARAM]),
        ),
        localUsername$,
      ]);

      const referrerUsername$ = latestQueryParamLocaStorage$.pipe(
        switchMap(async ([queryParamUserName, localUsername]) => {
          return queryParamUserName || localUsername;
        }),
        filter(isDefined),
      );

      const referralTokenCollection =
        this.datastore.collection<ReferralTokenCollection>(
          'referralToken',
          query =>
            query.search(
              referrerUsername$.pipe(
                map(username => ({
                  referrer_username: username,
                })),
              ),
            ),
        );

      const validUsername$ = combineLatest([
        referralTokenCollection.status$,
        referrerUsername$,
      ]).pipe(
        filter(
          ([status, _]) =>
            (isDefined(status.error) && !status.error) || status.ready,
        ),
        map(([_, username]) => username),
      );

      const userCollection = this.datastore.collection<UsersCollection>(
        'users',
        query => query.where('username', 'equalsIgnoreCase', validUsername$),
      );

      this.user$ = userCollection.valueChanges().pipe(
        map(users => users[0]),
        filter(isDefined),
      );

      const giveGetFooterClosed$ = this.localStorage.get('giveGetFooterClosed');

      this.showGiveGetFooter$ = combineLatest([
        validUsername$,
        giveGetFooterClosed$,
        this.auth.isLoggedIn(),
      ]).pipe(
        map(([username, footerClosed, isLoggedIn]) => {
          return !isLoggedIn && isDefined(username) && !footerClosed;
        }),
      );

      const currencyToUse$ = this.giveGetService.getCurrencyToUse();
      const bonusAmountValue$ =
        this.giveGetService.getBonusAmount(currencyToUse$);
      this.bonusAmount$ = combineLatest([
        currencyToUse$,
        bonusAmountValue$,
      ]).pipe(
        map(([currency, bonusAmount]) => {
          return this.currencyPipe.transform(bonusAmount, currency.code, {
            withPrecision: false,
          });
        }),
      );

      this.isReady$ = combineLatest([this.user$, this.bonusAmount$]).pipe(
        map(([user, bonusAmount]) => isDefined(user) && isDefined(bonusAmount)),
      );
      this.subscriptions.add(
        this.user$.subscribe(user =>
          this.localStorage.set('giveGetReferrerUsername', user.username),
        ),
      );
      this.subscriptions.add(
        latestQueryParamLocaStorage$.subscribe(
          ([queryParam, giveGetReferrer]) => {
            if (queryParam && queryParam !== giveGetReferrer) {
              this.localStorage.set('giveGetFooterClosed', false);
            }
          },
        ),
      );

      this.giveGetService.setReferralTokenCookie();
    } else {
      this.showGiveGetFooter$ = of(false);
    }
  }

  async closeFooter(): Promise<void> {
    this.showGiveGetFooter$ = of(false);

    const user = await firstValueFrom(this.user$.pipe(untilDestroyed(this)));
    const bonusAmount = await firstValueFrom(
      this.bonusAmount$.pipe(untilDestroyed(this)),
    );

    this.modalService
      .open('GiveGetEmailModal', {
        closeable: false,
        inputs: {
          bonusAmount: bonusAmount?.split(' ')[0],
          referrerDisplayName: user.displayName,
        },
        size: ModalSize.SMALL,
      })
      .afterClosed()
      .then((emailAddress: string) => {
        if (emailAddress) {
          this.datastore
            .createDocument<ReferralInvitationsCollection>(
              'referralInvitations',
              {
                id: emailAddress,
                email: emailAddress,
                status: ReferralInvitationStatusApi.PENDING,
                canResend: true,
              },
              {
                referrerUsername: user.username,
              },
            )
            .then(response => {
              if (response.status === 'success') {
                this.toastAlertService.openById('give-get-email-send-success');
                this.localStorage.set('giveGetFooterClosed', true);
              } else {
                this.showGiveGetFooter$ = of(true);
                this.toastAlertService.openById('give-get-email-send-error');
              }
            });
        } else {
          this.localStorage.set('giveGetFooterClosed', true);
        }
      });
  }

  openLoginSignupModal(): void {
    this.showGiveGetFooter$ = of(false);
    this.modalService
      .open('LoginSignupModal', {
        closeable: true,
        edgeToEdge: true,
        mobileFullscreen: true,
        inputs: {
          form: LoginOrSignup.SIGNUP,
          enableLoginSignupSwitch: false,
        },
        size: ModalSize.XSMALL,
      })
      .afterClosed()
      .then(user => {
        if (user) {
          // The UserSignup post flow manages adding the new user as a referral child.
          // Therefore, we do not need an email pop-up after the user successfully signs up.
          this.localStorage.set('giveGetFooterClosed', true);
          this.router.navigate([`/new-client`]);
        } else {
          this.showGiveGetFooter$ = of(true);
        }
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
